.container {
    background-color: var(--alpha-500);
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
}

.main {
    background-color: var(--alpha-0);
    padding: env(--space-96) env(--space-40);
    border-radius: env(--radius-8);
    width: 90%;
}

.content {
    width: 100%;
}

@media (min-width: env(--breakpoint-small)) {
    .main {
        width: 100%;
        max-width: calc(env(--space-200) * 3);
    }

    .content {
        width: 100%;
        max-width: calc(env(--space-200) * 2);
    }
}
